import React, { PureComponent } from "react"
import { Waypoint } from "react-waypoint"

export default class FadeIn extends PureComponent {
  constructor(props) {
    super(props)

    this.onEnter = this.onEnter.bind(this)

    this.duration = this.props.duration || "500"
    this.delay = this.props.delay || 300
    this.ease = this.props.ease || "ease-in"

    this.state = {
      waypointActive: true,
      playState: "paused",
    }
  }

  componentWillUnmount() {
    this.setState({
      waypointActive: false,
      playState: "paused",
    })
  }

  onEnter() {
    this.setState({
      waypointActive: false,
      playState: "running",
    })
  }

  render() {
    const style = {
      animation: `fadeIn ${this.duration}ms ${this.ease} both ${this.state.playState} ${this.delay}ms`,
    }
    return (
      <div {...this.props}>
        {this.state.waypointActive && (
          <Waypoint onEnter={this.onEnter} scrollableAncestor={"window"} />
        )}
        <div
          className={this.state.playState === "running" ? "animate" : ""}
          style={style}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}
